import { countryCodes } from "../pages/contacts/country-code";

export const countryCodeObject = countryCodes.reduce((acc, { code, label }) => {
  acc[label as keyof typeof acc] = code;
  return acc;
}, {} as Record<string, string>);

export type CountryShortName = keyof typeof countryCodeObject;

export const removeCountryCode = (params: {
  phoneNumber?: string | null;
  country?: CountryShortName;
  numberMasking?: boolean;
  first_name?: string | null;
  last_name?: string | null;
}) => {
  const { phoneNumber, numberMasking, first_name, last_name } = params || {};

  let phoneNumberString = "";
  let code = "";

  if (typeof phoneNumber === "string") {
    const matchingCountryCode = countryCodes.find(({ code }) =>
      phoneNumber.startsWith(code)
    )?.code;
    code = matchingCountryCode || "";

    if (matchingCountryCode) {
      phoneNumberString = phoneNumber.replace(matchingCountryCode, "");
    } else {
      phoneNumberString = phoneNumber;
    }
  }
  if (numberMasking) {
    phoneNumberString =
      phoneNumberString.slice(0, 2) + "XXXXXXXX" + phoneNumberString.slice(-2);
  }
  let name = `${first_name || ""} ${last_name || ""}`.trim();
  const customerPhoneWithoutPlus = name.replace("+", "");
  if (
    name &&
    numberMasking &&
    customerPhoneWithoutPlus &&
    !isNaN(Number(customerPhoneWithoutPlus))
  ) {
    name = name.slice(0, 2) + "XXXXXXXX" + name.slice(-2);
  }
  return { phone: phoneNumberString, name, code: code || "" };
};

export const getContactWithCountryCode = (params: {
  number?: string;
  country?: CountryShortName;
  numberMasking?: boolean;
  first_name?: string | null;
  last_name?: string | null;
}) => {
  const {
    country,
    number,
    numberMasking,
    first_name = "",
    last_name = "",
  } = params || {};
  let phoneNumber = number || "";
  const matchingCountryCode = countryCodes.find(({ code }) =>
    phoneNumber.startsWith(code)
  )?.code;
  let countryCode = matchingCountryCode || countryCodeObject[country || "IN"];
  const originalPhoneNumberWithoutCountryCode =
    number?.replace(countryCode, "") || "";

  if (typeof number === "string") {
    if (matchingCountryCode) {
      phoneNumber = number.replace(matchingCountryCode, "");
      countryCode = matchingCountryCode;
    } else {
      phoneNumber = number;
    }
  }

  if (numberMasking && phoneNumber) {
    phoneNumber = phoneNumber.slice(0, 2) + "XXXXXXXX" + phoneNumber.slice(-2);
  }
  let name = `${first_name || ""} ${last_name || ""}`.trim();
  const customerPhoneWithoutPlus = name.replace("+", "");
  if (
    name &&
    numberMasking &&
    customerPhoneWithoutPlus &&
    !isNaN(Number(customerPhoneWithoutPlus))
  ) {
    name = name.slice(0, 2) + "XXXXXXXX" + name.slice(-2);
  }
  const phone = [countryCode || "", phoneNumber || ""];
  return {
    phone,
    name: name || phone?.join(" ") || "",
    originalPhoneNumberWithoutCountryCode,
  };
};
