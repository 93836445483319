import { WhatsappContact } from "../../store/api/types/get-whatsapp-contacts.types";
import { WhatsappMessage } from "../../store/api/types/get-whatsapp-messages.types";
import { AppRoutesObject } from "../app-routes.component";

const decrypt = (text?: string | null | undefined | number) => {
  if (text) {
    const decodedString = atob(`${text}`);
    return parseInt(decodedString);
  }
  return 0;
};

export const getOrgIdFromUrl = () => {
  const searchQuery = window?.location?.href || "";
  const urlParts = searchQuery.split(`${AppRoutesObject.WpChat.url}/`);
  const params = urlParts?.[(urlParts?.length || 0) - 1] || "";
  const [matchedOrgId, queryParamsString] = params.split("?");
  if (searchQuery?.includes(AppRoutesObject.WpChat.url)) {
    if (queryParamsString) {
      const query = new URLSearchParams(queryParamsString);
      const isApp = query.get("mode") === "app";
      if (isApp) {
        return Number(matchedOrgId);
      } else {
        return Number(decrypt(matchedOrgId));
      }
    } else {
      return decrypt(matchedOrgId);
    }
  }

  return 0;
};

const getPrefix = () => {
  const version = "1.2";
  const orgId = getOrgIdFromUrl();
  const prefix = `${version}-${orgId}`;
  return prefix;
};

const clearWhatsappCache = (params: {
  checkVersions?: boolean;
  preserveContacts?: boolean;
}) => {
  const { checkVersions, preserveContacts } = params;
  const prefix = getPrefix();
  const keys = Object.keys(localStorage);
  const whatsappKeys = keys.filter((key) => {
    const keyParts = key.split(",");
    const isWhatsapp = keyParts?.[2] === "whatsapp";
    const oldPrefix = keyParts?.[1] || "";
    return (
      isWhatsapp && (checkVersions ? !compareVersions(oldPrefix, prefix) : true)
    );
  });
  whatsappKeys
    .filter((key) => {
      if (preserveContacts) {
        return !key.includes("contacts");
      }
      return true;
    })
    .map((key) => {
      localStorage.removeItem(key);
      return null;
    });
};

const setWhatsappCache = (key: string, value: string) => {
  try {
    localStorage.setItem(key, value);
  } catch (e) {
    clearWhatsappCache({ preserveContacts: true });
  }
};

const compareVersions = (prefix1?: string | null, prefix2?: string | null) => {
  if (prefix1 && prefix2) {
    const [version1] = prefix1.split("-");
    const [version2] = prefix2.split("-");
    return version1 === version2;
  }
  return false;
};

export const setContactsInStorage = (contacts: WhatsappContact[]) => {
  const userId = localStorage.getItem("userId");
  const prefix = getPrefix();
  if (userId && contacts?.length) {
    setWhatsappCache(
      `${userId},${prefix},whatsapp,contacts`,
      JSON.stringify(contacts)
    );
    if (!localStorage.getItem(`${userId},whatsapp,local_data_set`)) {
      setWhatsappCache(`${userId},whatsapp,local_data_set`, prefix);
    } else {
      const setPrefix = localStorage.getItem(
        `${userId},whatsapp,local_data_set`
      );
      if (prefix && setPrefix && !compareVersions(prefix, setPrefix)) {
        //delete old cache
        clearWhatsappCache({ checkVersions: true });
      }
    }
  }
};

export const getContactsFromStorage = (): WhatsappContact[] => {
  const userId = localStorage.getItem("userId");
  const prefix = getPrefix();
  if (userId) {
    const contactsString = localStorage.getItem(
      `${userId},${prefix},whatsapp,contacts`
    );
    if (contactsString) {
      return JSON.parse(contactsString);
    }
  }
  return [];
};

export const getMessagesFromStorageByChatId = (params: {
  chat_id: string;
}): WhatsappMessage[] => {
  const { chat_id } = params;
  const prefix = getPrefix();
  const userId = localStorage.getItem("userId");
  if (chat_id && userId) {
    const messagesString = localStorage.getItem(
      `${userId},${prefix},whatsapp,messages,${chat_id}`
    );
    if (messagesString) {
      return JSON.parse(messagesString);
    }
  }
  return [];
};

export const getMessagesByClientWaNumberFromStorage = (params: {
  clientWaNumber: string;
}): WhatsappMessage[] => {
  const { clientWaNumber } = params;
  const prefix = getPrefix();
  const contacts = getContactsFromStorage();
  const chat = contacts?.find((c) => c?.contact?.wa_id === clientWaNumber);
  const userId = localStorage.getItem("userId");
  if (userId && chat?._id) {
    const messagesString = localStorage.getItem(
      `${userId},${prefix},whatsapp,messages,${chat._id}`
    );
    if (messagesString) {
      return JSON.parse(messagesString);
    }
  }
  return [];
};

export const setMessagesInStorage = (params: {
  chat_id: string;
  messages: WhatsappMessage[];
}) => {
  const { chat_id, messages } = params;
  const prefix = getPrefix();
  const userId = localStorage.getItem("userId");
  if (userId) {
    setWhatsappCache(
      `${userId},${prefix},whatsapp,messages,${chat_id}`,
      JSON.stringify(messages.slice(-100))
    );
  }
};

export const getMessagesByQueryParamsFromStorage = () => {
  const searchQuery = window?.location?.href || "";
  if (searchQuery) {
    const hasActiveChatId = searchQuery.includes("activeChatId");
    if (hasActiveChatId) {
      const activeChatId =
        searchQuery.split("activeChatId")?.[1]?.replace("=", "") || "";
      return getMessagesFromStorageByChatId({ chat_id: activeChatId });
    }
    const hasClientWaNumber = searchQuery.includes("clientWaNumber");
    if (hasClientWaNumber) {
      const clientWaNumber =
        searchQuery.split("clientWaNumber")?.[1]?.replace("=", "") || "";
      return getMessagesByClientWaNumberFromStorage({ clientWaNumber });
    }
  }

  return [];
};

export const getMessagesFromStorage = (): {
  [x: string]: WhatsappMessage[];
} => {
  const userId = localStorage.getItem("userId");
  if (userId) {
    const keys = Object.keys(localStorage);
    const messages: { [x: string]: WhatsappMessage[] } = {};
    const messageKeys = keys.filter((k) => {
      const keyParts = k?.split(",");
      const isWhatsapp = keyParts?.[2] === "whatsapp";
      const isMessage = keyParts?.[1] === "messages";
      return isWhatsapp && isMessage;
    });
    messageKeys.map((key) => {
      const keyParts = key?.split(",");
      const chat_id = keyParts?.[(keyParts?.length || 0) - 1] || "";
      const messagesString = localStorage.getItem(key);
      messages[chat_id] = messagesString ? JSON.parse(messagesString) : [];
      return null;
    });
  }
  return {};
};
