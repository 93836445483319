import dayjs from "dayjs";

export const sizes = {
  successHeader: "450px",
  successHeaderClip: "420px",
  successTitleSize: "130px",
} as const;
type SizeKey = keyof typeof sizes;
export type Size = (typeof sizes)[SizeKey];

export const ulluColors = {
  transparent: "transparent",

  primary: "#1F2123",
  primaryRGB: "31, 33, 35",

  pink: "#ffd0dd",
  pinkRGB: "255, 208, 221",

  yellow: "#FBE277",
  yellowRGB: "251, 226, 119",

  black: "#000000",
  blackRGB: "0, 0, 0",

  white: "#ffffff",
  whiteRGB: "255, 255, 255",

  lightBlue: "#e2ecff",
  lightBlueRGB: "226, 236, 255",

  blue: "#9AC2FA",
  blueRGB: "154, 194, 250",

  darkBlue: "#1d47f8",
  darkBlueRGB: "29, 71, 248",

  backgroundColor: "#F4F6FA",
  backgroundColorRGB: "244, 246, 250",

  buttonTextColor: "#2C2D2E",
  buttonTextColorRGB: "44, 45, 46",

  secondaryTextColor: "#484C4F",
  secondaryTextColorRGB: "72, 76, 79",

  greyLight1: "#DBDEE4",
  greyLight1RGB: "219, 222, 228",

  greyLight2: "#455a64",
  greyLight2RGB: "69, 90, 100",

  greyLight3: "#D2DBE8",
  greyLight3RGB: "210, 219, 232",

  greyDark1: "#455464",
  greyDark1RGB: "69, 84, 100",

  extraColor: "#ab4296",
  extraColorRGB: "171, 66, 150",

  secondary: "#1e48f7",
  secondaryRGB: "30, 72, 247",

  reviewsBackgroundColor: "#d2efc8",
  reviewsBackgroundColorRGB: "210, 239, 200",

  disabledColor: "#BAC0D9",
  disabledColorRGB: "186, 192, 217",

  inputBorderColor: "#737f8b",
  inputBorderColorRGB: "115, 127, 139",

  placeholderColor: "#98a1ad",
  placeholderColorRGB: "152, 161, 173",

  shrinkablePlaceholderColor: "#A6B6BE",
  shrinkablePlaceholderColorRGB: "166, 182, 190",

  chosenNumberBackground: "#E8EDFE",
  chosenNumberBackgroundRGB: "232, 237, 254",

  chosenNumberShadow: "#3D52A9",
  chosenNumberShadowRGB: "61, 82, 169",

  notificationColor: "#e24c4b",
  notificationColorRGB: "226, 76, 75",

  notificationTextColor: "#fcffff",
  notificationTextColorRGB: "252, 255, 255",

  termsConditions: "#61626b",
  termsConditionsRGB: "97, 98, 107",

  required: "#f96464",
  requiredRGB: "249, 100, 100",

  lineGradient: `linear-gradient(to right, rgba(255, 255, 255, 1),transparent)`,

  alertColor: "#EC4F4F",
  alertColorRGB: "236, 79, 79",

  topLeftCircle: "#f8d4de",
  topLeftCircleRGB: "248, 212, 222",

  bottomLeftBox: "#5e666f",
  bottomLeftBoxRGB: "94, 102, 111",

  topRightBox: "#dfd866",
  topRightBoxRGB: "223, 216, 102",

  bottomRightCircle: "#95c870",
  bottomRightCircleRGB: "149, 200, 112",

  error: "#ff4d4f",
  errorRGB: "255, 77, 79",

  bannerColor: "#ffb9b8",
  bannerColorRGB: "255, 185, 184",

  successBanner: "#eae7b0",
  successBannerRGB: "234, 231, 176",

  validTill: "#ab4296",
  validTillRGB: "171, 66, 150",

  disabledArrow: "#AAB2BD",
  disabledArrowRGB: "170, 178, 189",

  expandedSearch: "#F8E9B7",
  expandedSearchRGB: "248, 233, 183",

  blueRectangle: "#5FBAE4",
  blueRectangleRGB: "95, 186, 228",

  pinkSquare: "#FFBFA1",
  pinkSquareRGB: "255, 191, 161",

  starterPack: "#fff1f5",
  starterPackRGB: "255, 241, 245",

  standardPack: "#FEF6D6",
  standardPackRGB: "254, 246, 214",

  ultimatePack: "#E1EDFE",
  ultimatePackRGB: "225, 237, 254",

  gold: "#FFD54B",
  goldRGB: "255, 213, 75",

  contactUs: "#C6DDFE",
  contactUsRGB: "198, 221, 254",

  active: "#4CAF50",
  activeRGB: "76, 175, 80",

  expired: "#E24C4B",
  expiredRGB: "226, 76, 75",

  purple: "#A584E8",
  purpleRGB: "165, 132, 232",

  pink2: "#FFF2DB",
  pink2RGB: "255, 242, 219",

  whatsappColor: "#64B161",
  whatsappColorRGB: "100, 177, 97",

  saveBackground: "#FFEDED",
  saveBackgroundRGB: "255, 237, 237",

  yellowBackground: "#E8DC6A",
  yellowBackgroundRGB: "232, 220, 106",

  ownerColor: "#61b9e5",
  ownerColorRGB: "97, 185, 229",
} as const;

export type UlluColorKey = keyof typeof ulluColors;
export type UlluColor = (typeof ulluColors)[UlluColorKey];

export const boxShadows = {
  greyDark2: `2px 4px 8px rgba(${ulluColors.secondaryTextColorRGB}, 0.18)`,
  chosenNumber: `0px 4px 8px rgba(${ulluColors.chosenNumberShadowRGB}, 0.08)`,
  smallShadow: `-1px 2px 4px rgba(58, 61, 74, 0.12)`,
} as const;

export const colors = {
  primary: "#1E48F7",
  primaryRGB: "30, 72, 247",

  alertRGB: "226, 76, 75",

  topLeftCircle: "#f8d4de",
  topLeftCircleRGB: "248, 212, 222",

  bottomLeftBox: "#5e666f",
  bottomLeftBoxRGB: "94, 102, 111",

  topRightBox: "#dfd866",
  topRightBoxRGB: "223, 216, 102",

  bottomRightCircle: "#95c870",
  bottomRightCircleRGB: "149, 200, 112",

  black: "#1F2123",
  blackRGB: "31, 33, 35",

  chosenNumberShadow: "#3D52A9",
  chosenNumberShadowRGB: "61, 82, 169",

  white: "#ffffff",
  whiteRGB: "255, 255, 255",

  secondary: "#1e48f7",
  secondaryRGB: "30, 72, 247",

  greyDark1: "#455464",
  greyDark1RGB: "69, 84, 100",

  transparent: "transparent",

  bannerColor: "#93C1EF",
  successColor: "#6BA740",
} as const;

type ColorKey = keyof typeof colors;
export type Color = (typeof colors)[ColorKey];

export const maxCharMessage = "Character limit reached.";
export const phoneNumberMessage = "Please enter correct number";

export const timeArray = [
  {
    value: "today",
    label: "Today",
  },
  {
    value: "yesterday",
    label: "Yesterday",
  },
  {
    value: "thisWeek",
    label: "This Week",
  },
  {
    value: "thisMonth",
    label: "This Month",
  },
  {
    value: "customTimePeriod",
    label: "Custom Time Period",
  },
] as const;

export type TimePeriodValue = (typeof timeArray)[number]["value"];

export const getCorrectTo = (to: string, timePeriod: string) => {
  const nowString = dayjs().endOf("day").toISOString();
  if (
    timePeriod === "today" ||
    timePeriod === "thisMonth" ||
    timePeriod === "thisWeek"
  ) {
    return nowString;
  } else if (timePeriod === "yesterday") {
    return dayjs().subtract(1, "day").endOf("day").toISOString();
  } else if (timePeriod === "customTimePeriod") {
    if (to) {
      return dayjs(to).endOf("day").toISOString();
    } else {
      return nowString;
    }
  }
  return to ? to : nowString;
};

export const callArray = [
  {
    value: "callHistory",
    label: "Call History",
  },
  {
    value: "contacts",
    label: "Contacts",
  },

  {
    value: "notes",
    label: "Notes",
  },
  {
    value: "reminders",
    label: "Reminders",
  },
  {
    value: "quickMessages",
    label: "Quick Messages",
  },
];

export const breakpoints = {
  xs: "480px",
  sm: "576px",
  md: "768px",
  lg: "992px",
  xl: "1200px",
  xxl: "1600px",
};

export type LoaderStatus = "idle" | "loading" | "success" | "failed";

export enum OrgStatus {
  Active = "Active",
  ExpiringSoon = "Expiring Soon",
  Expired = "Expired",
  Trial = "Trial",
  TrialExpired = "Trial Expired",
}

export const DISPLAY_DATE_FORMAT = "DD MMM YYYY";

export const DISPLAY_DATETIME_FORMAT = "DD/MM/YYYY h:mm A";

export const QUERY_DATE_FORMAT = "YYYY-MM-DD";

export const SHORT_DATE_TIME = "DD/MM/YY hh:mm A";

export const DATE_MONTH_TIME = "DD MMM hh:mm A";

export const DATE_TIME_FORMAT = "DD MMM YYYY hh:mm A";

export const MONTH_DAY_YEAR_TIME = "MMM DD, YYYY hh:mm A";

export const TIME_FORMAT = "hh:mm A";

export const DISPLAY_DATE_TIME_FORMAT = "DD MMM YYYY hh:mm:ss A";

export const DEFAULT_PAGE_SIZE = 10;

export const DEFAULT_CURRENT_PAGE = 0;

export const DEFAULT_TOTAL_ITEMS = 0;

export const DEFAULT_TOTAL_PAGES = 0;

export const FIRST_PAGE = 0;

export const newTeamName = "New Team";

export const getRandomBetween = (min: number, max: number) => {
  if (!min || !max) return 0;

  if (!max) {
    max = min;
    min = 0;
  }
  return min + Math.floor(Math.random() * (max - min + 1));
};

export const countWords = (s: string) => {
  s = s.replace(/(^\s*)|(\s*$)/gi, "");
  s = s.replace(/[ ]{2,}/gi, " ");
  s = s.replace(/\n /, "\n");
  return s.split(" ").filter(function (str) {
    return str !== "";
  }).length;
};

export const UNASSIGNED = {
  label: "Unassigned",
  value: -1,
  initials: "UA",
};
export const SUCCESS_NOTIFICATION_DURATION = 2;

export const LAST_ONLINE_DURATION = 5000;

export const progressColor = {
  gray: "#575A5D",
  blue: "#34B9F6",
  red: "#E65959",
};

export const broadBarColor = {
  high: "#4CAF50",
  medium: "#E8B613",
  low: "#E65959",
};

export const broadStatusColor = {
  pending: "#F0B307",
  completed: "#4CAF50",
  cancelled: "#61626B",
};

export const checkPhoneNumberFormat = (params: {
  phone: string;
  check: boolean;
}) => {
  const { check, phone } = params;
  if (check) {
    if (!phone) {
      return false;
    }
    const internalSpacesRemoved = phone.split(" ").join("");
    return !!internalSpacesRemoved.match(/^([+]\d{2})?\d{10}$/);
  }
  return true;
};
