import { PropsWithChildren } from "react";
import {
  useDragonflyAccountQuery,
  useGetMemberQuery,
  useGetSummariesQuery,
  useGetWabaTokensQuery,
  useLoginOrgQuery,
  useUsersSyncQuery,
} from "../../store/api/api";
import { AppRoutesObject } from "../../pages/app-routes.component";
import { useLocation, useMatch } from "react-router-dom";
import { AppLayout } from "../app-layout/app-layout.component";
import { getOrgIdFromUrl } from "../../pages/wp-chat/locally-stored-data";

export const RoleBasedRoute = (props: PropsWithChildren) => {
  const { data: usersSync } = useUsersSyncQuery();
  const { data: user } = useGetMemberQuery(usersSync?.user?.id || 0, {
    skip:
      !usersSync?.user?.id ||
      !usersSync?.org?.id ||
      !usersSync?.current_subscription?.subscription,
  });

  const { data: loginOrgResponse } = useLoginOrgQuery(usersSync?.org?.id || 0, {
    skip: !usersSync?.org?.id,
  });

  const {
    data: wabaTokens,
    isFetching,
    isLoading,
    isUninitialized,
  } = useGetWabaTokensQuery(undefined, {
    skip: !usersSync?.org?.id || !loginOrgResponse?.userData?.id,
  });
  const tokensLoading = isUninitialized || isFetching || isLoading;

  const { data: summaries } = useGetSummariesQuery();
  const userOrgIds = user?.orgs?.map((o) => o.id) || [];
  const userSummaries =
    summaries?.filter?.((s) => userOrgIds.includes(s.org_id)) || [];
  const location = useLocation();
  const route = location?.pathname?.split("/")?.[1] || "";
  const match = useMatch(`${AppRoutesObject.WpChat.url}/:orgId`);
  const isNewWhatsappScreen = !!match?.params?.orgId;
  const isWhatsappActive = (wabaTokens || [])
    .filter((token) => userOrgIds.includes(token.org_id))
    .some((w) => !!w?.token);
  const accountsQuery = useDragonflyAccountQuery();
  const dragonFlyOnlyAccount = !!accountsQuery?.data?.find((a) => {
    return a.org_id === String(getOrgIdFromUrl());
  });
  const accountsLoading =
    accountsQuery.isUninitialized ||
    accountsQuery.isFetching ||
    accountsQuery.isLoading;
  const hasDragonflyInTokens = (wabaTokens || [])
    .filter((token) => userOrgIds.includes(token.org_id))
    .some((w) => !!w?.dragonfly);
  const isDragonflyActive =
    tokensLoading || accountsLoading
      ? true
      : dragonFlyOnlyAccount || hasDragonflyInTokens;
  const isCRMActive = userSummaries.some((s) => {
    return s.is_paid_crm_active || s.is_trial_crm_active;
  });

  const isLoggedInUserStaff =
    user?.orgs?.every((o) => o?.role_slug === "member") || false;
  const isLoggedInUserManager =
    user?.orgs?.every((o) => o?.role_slug === "manager") || false;
  if (usersSync?.user?.role_slug) {
    const { access, reason } = getAccessPerRole(
      `/${route}`,
      isLoggedInUserStaff,
      isLoggedInUserManager,
      {
        crm: isCRMActive,
        whatsapp: isWhatsappActive,
        isDragonflyActive,
        isRouteDragonfly: isNewWhatsappScreen,
      }
    );

    if (access) {
      return <>{props.children}</>;
    } else {
      return (
        <AppLayout>
          <div className="flex justify-center items-center grow">
            <span className="text-lg">{reason}</span>
          </div>
        </AppLayout>
      );
    }
  }

  return <>{props.children}</>;
};

export const getAccessPerRole = (
  route: string,
  isStaff: boolean,
  isManager: boolean,
  features: {
    crm: boolean;
    whatsapp: boolean;
    isDragonflyActive: boolean;
    isRouteDragonfly: boolean;
  }
) => {
  if (features.isRouteDragonfly) {
    if (features.isDragonflyActive) {
      return { access: true, reason: "" };
    } else {
      return { access: false, reason: "dragonfly is not active for this user" };
    }
  }
  switch (route) {
    default:
      return { access: true, reason: "" };
    case AppRoutesObject.Integrations.url:
      if (isStaff || isManager) {
        return {
          access: false,
          reason: "Staff does not have access to Integrations",
        };
      }
      return {
        access: features.crm,
        reason: "CRM is not active in any of yours Orgs",
      };
    case AppRoutesObject.Automations.url:
      if (isStaff || isManager) {
        return {
          access: false,
          reason: "Staff does not have access to Automations",
        };
      }
      return {
        access: features.crm,
        reason: "CRM is not active in any of yours Orgs",
      };
    case AppRoutesObject.Tasks.url:
      return {
        access: features.crm,
        reason: "CRM is not active in any of yours Orgs",
      };
    case AppRoutesObject.Settings.url:
      if (isStaff) {
        return {
          access: false,
          reason: "Staff does not have access to Settings",
        };
      }
      return {
        access: true,
        reason: "",
      };
  }
};
