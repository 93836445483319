import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import dayjs from "dayjs";
import { WhatsappTemplate } from "./types/get-whatsapp-templates.types";

export interface CampaignAnalytics {
  failed: number;
  sent: number;
  delivered: number;
  read: number;
  responded: number;
  waiting: number;
  unsubscribed: number;
  total: number; // total is recipients
}

export interface CampaignCreatedBy {
  user_id: number;
  name: string;
}

// export interface CampaignTemplate {
//   name: string;
//   parameter_format: string;
//   components: any[];
//   language: string;
//   status: string;
//   category: string;
//   id: string;
// }

export interface CampaignRecipients {
  recipient: string;
  components: any[];
}

export interface CampaignContacts {
  contact: string;
  message: string;
  status: string;
  _id: string;
}

export interface Campaign {
  analytics: CampaignAnalytics;
  created_by: CampaignCreatedBy;
  _id: string;
  name: string;
  description: string;
  org_id: number;
  template_name: string;
  template: WhatsappTemplate;
  status: string;
  recipients: CampaignRecipients;
  scheduled_at: string;
  contacts: CampaignContacts[];
  createdAt: string;
  updatedAt: string;
  __v: number;
  percentages?: {
    sentPercentage: string;
    deliveredPercentage: string;
    readPercentage: string;
    failedPercentage: string;
    respondedPercentage: string;
    unsubscribedPercentage: string;
  };
}

export interface CampaignsResponse {
  data: Campaign[];
  message: string;
  lastSync?: string;
}

export interface SingleCampaignResponse {
  data: Campaign;
  message: string;
}

export interface QueryParams {
  page: number;
  limit: number;
}

export interface IMessage {
  _id: string;
  status: string;
  timestamp?: string;
  contact: string;
  statuses?: IStatuses[];
}

export interface CampaignDetailsSheet {
  "RECEPIENT NAME": string;
  PHONE: string;
  STATUS: string;
  TIME: string | null;
}

export interface AccountPhoneID {
  phone_number_id: string;
}

export interface AccountContactId {
  phone_number_id: string;
  profile: any;
  wa_id?: string;
}

export interface IContact {
  _id: string;
  lead_id: number;
  account?: AccountPhoneID;
  contact: AccountContactId;
}

export interface IStatuses {
  id?: string;
  recipient_id?: string;
  status?: string;
  timestamp?: string;
}

export interface IndividualContact {
  status: string;
  _id: string;
  message: IMessage;
  contact: IContact;
}

export interface CampaignAnalyticsPercentages {
  sentPercentage?: number;
  deliveredPercentage?: number;
  readPercentage?: number;
  failedPercentage?: number;
  respondedPercentage?: number;
  unsubscribedPercentage?: number;
}

export interface IdividualCampaign {
  analytics: CampaignAnalytics;
  created_by: CampaignCreatedBy;
  createdAt: string;
  _id: string;
  name: string;
  description: string;
  org_id: number;
  template_name: string;
  template?: WhatsappTemplate;
  status: string;
  recipients: CampaignRecipients;
  scheduled_at: string;
  contacts: IndividualContact[];
  lastSync?: string;
  percentages?: CampaignAnalyticsPercentages;
  leads?: {
    name: string;
    id: number;
  }[];
}

export interface HealthEntity {
  entity_type: string;
  id: string;
  can_send_message: string;
}

export interface PhoneNumberDetails {
  account_mode: string;
  verified_name: string;
  status: string;
  quality_score: { score: string };
  name_status: string;
  messaging_limit_tier: string;
  health_status: {
    can_send_message: string;
    entities: HealthEntity[];
  };
}

export interface BusinessDetails {
  name: string;
  currency: string;
  timezone_id: string;
  message_template_namespace: string;
  business_verification_status: string;
}

export interface WhatsAppApiResponse {
  data: {
    account: {
      _id: string;
      org_id: number;
      phone_number: string;
      business_id: string;
    };
    template_sent: number;
    phone_number_details: PhoneNumberDetails;
    business_details: BusinessDetails;
  };
  message: string;
}

export interface TransformedWhatsAppData {
  connectionStatus: string;
  qualityScore: string;
  messageLimit: string;
  businessName: string;
  verifiedName: string;
  canSendMessages: boolean;
  template_sent: number;
  businessId: string;
}

export const campaignApi = createApi({
  reducerPath: "campaignApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_HOST,
    credentials: "include",
  }),
  endpoints: (builder) => ({
    getCampaigns: builder.query<CampaignsResponse, void>({
      query: () => ({
        url: `/dragonfly/whatsapp/campaigns`,
      }),
      transformResponse: (response: CampaignsResponse) => {
        const lastSync = `Last Synced: ${dayjs().format(
          "ddd, MMM D [at] h:mm A"
        )}`;
        const transformedData = response?.data?.map((campaign) => {
          const total = campaign?.analytics?.total || 1; // Avoid division by zero
          return {
            ...campaign,
            percentages: {
              sentPercentage: ((campaign.analytics.sent / total) * 100).toFixed(
                2
              ),
              deliveredPercentage: (
                (campaign.analytics.delivered / total) *
                100
              ).toFixed(2),
              readPercentage: ((campaign.analytics.read / total) * 100).toFixed(
                2
              ),
              failedPercentage: (
                (campaign.analytics.failed / total) *
                100
              ).toFixed(2),
              respondedPercentage: (
                (campaign.analytics.responded / total) *
                100
              ).toFixed(2),
              unsubscribedPercentage: (
                (campaign.analytics.unsubscribed / total) *
                100
              ).toFixed(2),
            },
          };
        });
        return { ...response, data: transformedData, lastSync };
      },
    }),
    getCampaignById: builder.query<IdividualCampaign, string>({
      query: (id) => ({
        url: `/dragonfly/whatsapp/campaigns/${id}`,
      }),
      transformResponse: (response: any) => {
        const lastSync = `Last Synced: ${dayjs().format(
          "ddd, MMM D [at] h:mm A"
        )}`;
        const campaign = response.data;
        const total = campaign.analytics.total || 1; // Avoid division by zero
        return {
          ...campaign,
          lastSync,
          percentages: {
            sentPercentage: ((campaign.analytics.sent / total) * 100).toFixed(
              2
            ),
            deliveredPercentage: (
              (campaign.analytics.delivered / total) *
              100
            ).toFixed(2),
            readPercentage: ((campaign.analytics.read / total) * 100).toFixed(
              2
            ),
            failedPercentage: (
              (campaign.analytics.failed / total) *
              100
            ).toFixed(2),
            respondedPercentage: (
              (campaign.analytics.responded / total) *
              100
            ).toFixed(2),
            unsubscribedPercentage: (
              (campaign.analytics.unsubscribed / total) *
              100
            ).toFixed(2),
          },
        };
      },
    }),
    getWhatsAppDetails: builder.query<TransformedWhatsAppData, void>({
      query: () => ({
        url: `/dragonfly/whatsapp`,
      }),
      transformResponse: (response: WhatsAppApiResponse) => {
        const phoneDetails = response?.data?.phone_number_details;
        const businessDetails = response?.data?.business_details;

        return {
          connectionStatus: phoneDetails.status,
          qualityScore: phoneDetails.quality_score.score,
          messageLimit: phoneDetails.messaging_limit_tier,
          businessName: businessDetails.name,
          businessId: response.data.account.business_id,
          verifiedName: phoneDetails.verified_name,
          canSendMessages:
            phoneDetails.health_status.can_send_message === "AVAILABLE",
          template_sent: response?.data?.template_sent,
        };
      },
    }),
  }),
});

export const {
  useGetCampaignsQuery,
  useLazyGetCampaignsQuery,
  useGetCampaignByIdQuery,
  useLazyGetCampaignByIdQuery,
  useGetWhatsAppDetailsQuery,
  useLazyGetWhatsAppDetailsQuery,
} = campaignApi;
