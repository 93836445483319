import { PropsWithChildren, Suspense, lazy } from "react";
import { Spin } from "antd";
import { Navigate, useLocation } from "react-router-dom";
import { AppRoutesObject } from "../../pages/app-routes.component";
import {
  useGetMemberQuery,
  useGetSummariesQuery,
  useUsersSyncQuery,
} from "../../store/api/api";
import { RoleBasedRoute } from "./role-based-route.component";
import dayjs from "dayjs";
import { selectSummariesWithFlags } from "../../store/api/api.selector";

const AccountSetup = lazy(
  () => import("../../pages/account-setup/account-setup.page")
);

const TrialContent = lazy(() => import("./trial-content.component"));
const IsMember = lazy(() => import("./is-member.component"));
const IsBizPhonePlanScreen = lazy(() => import("./is-biz-phone-plan"));
const NonOwnerInLitePlan = lazy(() => import("./non-owner-in-lite-plan"));

const Teams = lazy(() => import("../../pages/teams/teams.page"));

const Loading = () => {
  return (
    <div className="flex justify-center items-center min-h-screen w-full">
      <Spin spinning={true} />
    </div>
  );
};

export const ProtectedRoute = (props: Readonly<PropsWithChildren>) => {
  const { children } = props;
  const location = useLocation();
  const { data: usersSync, isLoading } = useUsersSyncQuery();
  const { data: user } = useGetMemberQuery(usersSync?.user?.id || 0, {
    skip: !usersSync?.user?.id,
  });

  const isLoggedInUserStaff =
    user?.orgs?.every((o) => o?.role_slug === "member") || false;

  const isLoggedInUserOwner =
    user?.orgs?.every((o) => o?.role_slug === "owner") || false;

  const { isFreeTier, isBizPhonePlan, isBizOrLitePlan, summaries } =
    useGetSummariesQuery(undefined, {
      selectFromResult: ({ data }) => selectSummariesWithFlags(data || []),
    });

  if (isLoading) {
    return <Loading />;
  }

  if (usersSync) {
    if (
      isBizPhonePlan &&
      summaries?.length &&
      !isLoggedInUserOwner &&
      !window?.JSBridge
    ) {
      return (
        <Suspense fallback={<Loading />}>
          <IsBizPhonePlanScreen />
        </Suspense>
      );
    }
    if (
      isBizOrLitePlan &&
      !isLoggedInUserOwner &&
      user?.orgs?.length &&
      !window?.JSBridge
    ) {
      return (
        <Suspense fallback={<Loading />}>
          <NonOwnerInLitePlan />
        </Suspense>
      );
    }

    if (isLoggedInUserStaff && isFreeTier) {
      return (
        <Suspense fallback={<Loading />}>
          <IsMember />
        </Suspense>
      );
    }

    if (!usersSync?.org?.id) {
      return (
        <Suspense fallback={<Loading />}>
          <AccountSetup />
        </Suspense>
      );
    }

    const validTill = usersSync?.current_subscription?.subscription?.valid_till
      ? dayjs(usersSync?.current_subscription?.subscription?.valid_till)
      : null;
    const isTrial =
      ((usersSync?.current_subscription?.is_trial === true &&
        usersSync?.current_subscription?.is_trial_active === false) ||
        dayjs().diff(validTill) > 0) &&
      summaries?.length === 1;

    if (isTrial) {
      return <Teams logoutBtn={true} />;
    }

    const allExpired =
      summaries?.every(
        (summary) => !summary.is_active && !summary.is_trial_active
      ) || false;

    if (allExpired && !!summaries?.length) {
      return <Teams logoutBtn={true} />;
    }
    const allTrialAndExpired =
      summaries?.every(
        (summary) => summary.is_trial && !summary.is_trial_active
      ) || false;

    if (allTrialAndExpired && !!summaries?.length) {
      return <Teams logoutBtn={true} />;
    }

    if (
      !usersSync?.current_subscription?.subscription &&
      summaries?.length === 1
    ) {
      return (
        <Suspense fallback={<Loading />}>
          <TrialContent>
            <RoleBasedRoute>{children}</RoleBasedRoute>
          </TrialContent>
        </Suspense>
      );
    }

    return <RoleBasedRoute>{children}</RoleBasedRoute>;
  }

  return (
    <Navigate
      to={"/login"}
      {...(RedirectableURLS.includes(location.pathname)
        ? {
            replace: true,
            state: { path: location.pathname + location.search },
          }
        : {})}
    />
  );
};

const RedirectableURLS = [
  AppRoutesObject.CallHistory.url,
  AppRoutesObject.Contacts.url,
];
