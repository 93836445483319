import {
  Checkbox,
  DatePicker,
  Divider,
  Form,
  FormInstance,
  Input,
  InputNumber,
  Select,
  Tooltip,
  Space,
} from "antd";
import {
  useGetAllLeadsCountQuery,
  useGetAllMembersQuery,
  useGetCustomFieldsQuery,
  useGetOrgLeadGroupsQuery,
  useGetOrgLeadStagesQuery,
  useGetOrgsQuery,
  useGetSummariesQuery,
  useGetTaskTypesQuery,
} from "../../store/api/api";
import { filterOption } from "../../utils/filter-option";
import { useState } from "react";
import { CloseCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { DATE_TIME_FORMAT, UNASSIGNED } from "../../utils/constants";
import { disableBeforeDate } from "../../utils/utils";
import { removeCountryCode } from "../../utils/remove-country-code";
import { Customer } from "../../store/api/types/get-customer.types";
import { useSelector } from "react-redux";
import { selectGlobalOrgId } from "../../store/global/global.selector";
import { useEffectOnce } from "../../hooks/use-effect-once";
import {
  AssignmentLimitNudge,
  LeadFullNudge,
} from "../../components/upgrade/nudges";
import { BasicModal } from "../../components/basic-modal/basic-modal.component";
import { getPlanLevel } from "../../components/purchase/constants";
import { selectLeadCountsWithLimits } from "../../store/api/api.selector";
import dayjs from "dayjs";
import { countryCodes } from "./country-code";

const CreateUpdateContactModal = (
  props: Readonly<{
    form: FormInstance<any>;
    onSuccess?: () => void;
    customer?: Customer;
    numberMasking: boolean;
  }>
) => {
  const { form, customer, numberMasking } = props;
  const { setFieldsValue } = form;
  const { data: orgs } = useGetOrgsQuery();
  const globalOrgId = useSelector(selectGlobalOrgId);
  const [selectedOrg, setSelectedOrg] = useState(
    (customer ? customer.org_id : globalOrgId) || 0
  );
  const { data } = useGetCustomFieldsQuery(selectedOrg, {
    skip: !selectedOrg,
  });
  const initialPhoneRows =
    (customer?.phones?.length || 0) > 1
      ? new Array((customer?.phones?.length || 0) - 1)
          .fill(0)
          .map((_, i) => i + 2)
      : [];

  const [phoneRows, setPhoneRows] = useState<number[]>(initialPhoneRows);
  const [emailRows, setEmailRows] = useState<number[]>(
    (customer?.email?.length || 0) > 1
      ? new Array((customer?.email?.length || 0) - 1)
          .fill(0)
          .map((_, i) => i + 2)
      : []
  );
  const [extraRows, setExtraRows] = useState<number[]>(
    (customer?.custom_fields?.length || 0) > 1
      ? new Array((customer?.custom_fields?.length || 0) - 1)
          .fill(0)
          .map((_, i) => i + 2)
      : []
  );

  const { data: taskTypesData } = useGetTaskTypesQuery();
  const usersQuery = useGetAllMembersQuery();
  const members =
    usersQuery?.data?.filter((u) => u.org_id === selectedOrg) || [];
  const { data: leadStages } = useGetOrgLeadStagesQuery(selectedOrg, {
    skip: !selectedOrg || isNaN(selectedOrg),
  });
  const { data: leadGroups } = useGetOrgLeadGroupsQuery(selectedOrg, {
    skip: !selectedOrg || isNaN(selectedOrg),
  });

  const [task, setTask] = useState(false);
  const [nudgePopup, setNudgePopup] = useState(false);
  const initialEmailValues: { [x: string]: string } = {};
  emailRows.map((emailRow) => {
    initialEmailValues[`email,${emailRow}`] =
      customer?.email?.[emailRow - 1] || "";
    return null;
  });
  const initialPhoneValues: { [x: string]: string } = {};
  phoneRows.map((phoneRow) => {
    const { code, phone } = removeCountryCode({
      phoneNumber: customer?.phones?.[phoneRow - 1]?.phone || "",
      numberMasking,
    });
    initialPhoneValues[`phone,${phoneRow}`] = phone;
    initialPhoneValues[`code,${phoneRow}`] = code;
    return null;
  });
  const initialCustomKeys: { [x: string]: string } = {};
  extraRows.map((extraRow) => {
    initialCustomKeys[`key,${extraRow}`] =
      customer?.custom_fields?.[extraRow - 1]?.key || "";
    return null;
  });
  const initialCustomValues: { [x: string]: string } = {};
  extraRows.map((extraRow) => {
    initialCustomValues[`value,${extraRow}`] = String(
      customer?.custom_fields?.[extraRow - 1]?.value || ""
    );
    return null;
  });

  useEffectOnce(() => {
    if (customer) {
      const primaryPhone = removeCountryCode({
        phoneNumber: customer?.phones?.[0]?.phone || "",
        numberMasking,
      });
      form.setFieldsValue({
        org_id: customer.org_id,
        name: `${customer.first_name || ""} ${customer.last_name || ""}`.trim(),
        [`phone,${1}`]: primaryPhone.phone,
        [`code,${1}`]: primaryPhone.code,
        additional_info: customer.additional_info || "",
        business_name: customer.business_name || "",
        [`email,${1}`]: customer?.email?.[0] || "",
        ...initialEmailValues,
        ...initialPhoneValues,
        website: customer.website || "",
        initials: customer?.address?.initials || "",
        text: customer?.address?.text || "",
        additional: customer?.address?.additional || "",
        source: customer?.source || "",
        custom_text_1: customer.custom_text_1,
        custom_text_2: customer.custom_text_2,
        custom_text_3: customer.custom_text_3,
        custom_text_4: customer.custom_text_4,
        custom_text_5: customer.custom_text_5,
        custom_numeric_2: customer.custom_numeric_2,
        custom_numeric_1: customer.custom_numeric_1,
        custom_date_1: customer?.custom_date_1
          ? dayjs(customer.custom_date_1)
          : null,
        custom_date_2: customer?.custom_date_2
          ? dayjs(customer.custom_date_2)
          : null,
        ...(customer?.source_type ? { source_type: customer.source_type } : {}),
        city: customer?.city || "",
        lead_stage_id: customer?.lead_stage_id,
        lead_group_id: customer?.lead_group_id,
        assignee_user_id: customer?.assignee_user_id || UNASSIGNED.value,
        deal_value: customer.deal_value || 0,
        [`key,${1}`]: customer.custom_fields?.[0]?.key || "",
        [`value,${1}`]: customer.custom_fields?.[0]?.value || "",
        ...initialCustomKeys,
        ...initialCustomValues,
      });
    } else {
      form.setFieldsValue({
        org_id: globalOrgId
          ? globalOrgId
          : orgs?.length === 1
          ? orgs[0]?.id
          : 0,
      });

      form.setFieldValue("name", form?.getFieldValue("first_name"));
      // setPhoneRows(form?.getFieldValue("phones") || []);
      let number = form?.getFieldValue("phones")?.[0]?.phone;
      if (number) {
        number = number?.replace(/\s/g, "")?.substr(3, 12);
        form.setFieldValue(`phone,1`, number);
      }
    }
  });
  const { data: summaries } = useGetSummariesQuery();
  const summary = summaries?.find((s) => s.org_id === selectedOrg);
  const planId = summary?.subscription?.plan_id || 0;
  const { isLiteOldOrNew } = getPlanLevel(planId);
  const [assignNudge, setAssignNudge] = useState(false);

  const { data: orgLeadCounts } = useGetAllLeadsCountQuery(undefined, {
    selectFromResult: ({ data }) =>
      selectLeadCountsWithLimits({
        leadCounts: data || [],
        summaries: summaries || [],
      }),
  });
  const orgLimits = orgLeadCounts?.find(
    (o) => o.org_id === (customer?.org_id || globalOrgId)
  );

  return (
    <Form
      form={form}
      className="flex flex-col space-y-4"
      labelCol={{ span: 7 }}
      labelAlign="left"
    >
      {/* Static Fields */}
      {customer ? null : (
        <Form.Item
          name="org_id"
          rules={[{ required: true, message: "Please select Org" }]}
          label="Org"
        >
          <Select
            disabled={!!customer}
            placeholder="Select Org"
            options={(orgs || []).map((org) => {
              return {
                label: `${org?.superfone_number || "-"}, ${
                  org.name.length > 50
                    ? org.name.substring(0, 50) + "..."
                    : org.name
                }`,
                value: org.id,
              };
            })}
            filterOption={filterOption}
            showSearch
            onChange={(value) => {
              setSelectedOrg(value);
              setFieldsValue({
                assignee_user_id: UNASSIGNED.value,
              });
            }}
          />
        </Form.Item>
      )}
      <Form.Item
        name="name"
        rules={[
          { required: true, whitespace: true, message: "Please enter Name" },
        ]}
        label="Name"
      >
        <Input placeholder="Enter Name" allowClear />
      </Form.Item>
      <div className="flex flex-col">
        <Form.Item
          name={`phone,1`}
          rules={[{ required: true, message: "Please enter Phone 1" }]}
          label="Phone 1"
        >
          <Space.Compact>
            <Form.Item name={`code,1`}>
              <Select
                showSearch
                defaultValue={countryCodes[0].code}
                style={{ width: 100 }}
                options={countryCodes.map((country) => ({
                  value: country.code,
                  label: `${country.code} (${country.label})`,
                }))}
              />
            </Form.Item>
            <Form.Item name={`phone,1`}>
              <Input
                name={"phoneNumber"}
                disabled={numberMasking}
                placeholder={`Enter Phone 1`}
                allowClear
                addonAfter={
                  <PlusOutlined
                    className={numberMasking ? "pointer-events-none" : ""}
                    onClick={() => {
                      setPhoneRows(
                        phoneRows?.length
                          ? [...phoneRows, phoneRows[phoneRows.length - 1] + 1]
                          : [2]
                      );
                    }}
                  />
                }
              />
            </Form.Item>
          </Space.Compact>
        </Form.Item>
        {phoneRows.map((phoneRow) => {
          return (
            <Form.Item
              key={phoneRow}
              name={`phone,${phoneRow}`}
              rules={[
                { required: false, message: `Please enter Phone ${phoneRow}` },
              ]}
              label={`Phone ${phoneRow}`}
            >
              <Space.Compact>
                <Form.Item name={`code,${phoneRow}`}>
                  <Select
                    showSearch
                    defaultValue={countryCodes[0].code}
                    style={{ width: 100 }}
                    options={countryCodes.map((country) => ({
                      value: country.code,
                      label: `${country.code} (${country.label})`,
                    }))}
                  />
                </Form.Item>
                <Form.Item name={`phone,${phoneRow}`}>
                  <Input
                    name={"phoneNumber"}
                    disabled={numberMasking}
                    placeholder={`Enter Phone ${phoneRow}`}
                    allowClear
                    addonAfter={
                      <PlusOutlined
                        className={numberMasking ? "pointer-events-none" : ""}
                        onClick={() => {
                          setPhoneRows(
                            phoneRows?.length
                              ? [
                                  ...phoneRows,
                                  phoneRows[phoneRows.length - 1] + 1,
                                ]
                              : [2]
                          );
                        }}
                      />
                    }
                  />
                </Form.Item>
              </Space.Compact>
            </Form.Item>
          );
        })}
      </div>
      <Form.Item
        name={"email,1"}
        rules={[{ required: false, message: `Please enter Email 1` }]}
        label={`Email 1`}
      >
        <Input
          placeholder={`Enter Email 1`}
          allowClear
          addonAfter={
            <PlusOutlined
              onClick={() => {
                setEmailRows(
                  emailRows?.length
                    ? [...emailRows, emailRows[emailRows.length - 1] + 1]
                    : [2]
                );
              }}
            />
          }
        />
      </Form.Item>
      <Form.Item
        name={"website"}
        rules={[{ required: false, message: "Please enter Website" }]}
        label={"Website"}
      >
        <Input placeholder={"Enter Website"} allowClear />
      </Form.Item>
      <Divider plain orientation="left">
        Address
      </Divider>
      <Form.Item
        name={"initials"}
        rules={[{ required: false, message: "Please enter House no" }]}
        label={"House no"}
      >
        <Input placeholder={"Enter House no"} allowClear />
      </Form.Item>
      <Form.Item
        name={"text"}
        rules={[{ required: false, message: "Please enter Address 1" }]}
        label={"Address 1"}
      >
        <Input placeholder={"Enter Address 1"} allowClear />
      </Form.Item>
      <Form.Item
        name={"additional"}
        rules={[{ required: false, message: "Please enter Address 2" }]}
        label={"Address 2"}
      >
        <Input placeholder={"Enter Address 2"} allowClear />
      </Form.Item>
      <Form.Item
        name={"city"}
        rules={[{ required: false, message: "Please enter City" }]}
        label={"City"}
      >
        <Input placeholder={"Enter City"} allowClear />
      </Form.Item>

      <div className="flex gap-6">
        <div className="flex-1">
          <h3 className="text-lg font-semibold mb-4">Lead Tracking Fields</h3>
          <Form.Item
            name={"source"}
            rules={[{ required: false, message: "Please enter Source" }]}
            label={"Source"}
          >
            <Input placeholder={"Enter Source"} allowClear />
          </Form.Item>
          <Form.Item
            name={"source_type"}
            rules={[{ required: false, message: "Please select Source Type" }]}
            label={"Source Type"}
          >
            <Select
              placeholder={"Select Source Type"}
              allowClear
              options={sourceTypeOptions}
              filterOption={filterOption}
              showSearch
            />
          </Form.Item>
          <Form.Item
            name={"deal_value"}
            rules={[{ required: false, message: "Please enter Deal Value" }]}
            label={"Deal Value"}
          >
            <Input placeholder={"Enter Deal Value"} allowClear type="number" />
          </Form.Item>
          <Form.Item
            name={"lead_group_id"}
            rules={[{ required: false, message: "Please select Lead Group" }]}
            label={"Lead Group"}
          >
            <Select
              placeholder={"Select Lead Group"}
              allowClear
              options={leadGroups?.map((leadGroup) => {
                return {
                  label: leadGroup.title,
                  value: leadGroup.id,
                };
              })}
              filterOption={filterOption}
              showSearch
            />
          </Form.Item>
          <Form.Item
            name={"campaign"}
            rules={[{ required: false, message: "Please enter Campaign" }]}
            label={"Campaign"}
          >
            <Input placeholder={"Enter Campaign"} allowClear />
          </Form.Item>
        </div>

        {/* List Two */}
        <div className="flex-1">
          <h3 className="text-lg font-semibold mb-4">Additional Fields</h3>
          <Form.Item
            name={"city"}
            rules={[{ required: false, message: "Please enter City" }]}
            label={"City"}
          >
            <Input placeholder={"Enter City"} allowClear />
          </Form.Item>
          <Form.Item
            name={"business_name"}
            rules={[{ required: false, message: "Please enter Business Name" }]}
            label={"Business Name"}
          >
            <Input placeholder={"Enter Business Name"} allowClear />
          </Form.Item>
          <Form.Item
            name={"requirement"}
            rules={[{ required: false, message: "Please enter Requirement" }]}
            label={"Requirement"}
          >
            <Input placeholder={"Enter Requirement"} allowClear />
          </Form.Item>
          <Form.Item
            name={"additional_info"}
            rules={[
              { required: false, message: "Please enter Additional Info" },
            ]}
            label={"Additional Info"}
          >
            <Input placeholder={"Enter Additional Info"} allowClear />
          </Form.Item>

          {/* Custom Fields from API */}
          {data?.map((field, index) => {
            if (field.type === "DATE_TIME") {
              return (
                <Form.Item
                  key={index}
                  name={field.customer_field}
                  rules={[
                    { required: false, message: `Please enter ${field.title}` },
                  ]}
                  label={
                    <Tooltip title={field.title}>
                      <span className="max-w-[150px] truncate inline-block">
                        {field.title}
                      </span>
                    </Tooltip>
                  }
                >
                  <DatePicker
                    format={DATE_TIME_FORMAT}
                    placeholder={DATE_TIME_FORMAT}
                    showTime={true}
                    className="w-full"
                    disabledDate={disableBeforeDate}
                  />
                </Form.Item>
              );
            } else if (field.type === "DATE") {
              return (
                <Form.Item
                  key={index}
                  name={field.customer_field}
                  rules={[
                    { required: false, message: `Please enter ${field.title}` },
                  ]}
                  label={
                    <Tooltip title={field.title}>
                      <span className="max-w-[150px] truncate inline-block">
                        {field.title}
                      </span>
                    </Tooltip>
                  }
                >
                  <DatePicker
                    format="YYYY-MM-DD"
                    placeholder="YYYY-MM-DD"
                    showTime={false}
                    className="w-full"
                    disabledDate={disableBeforeDate}
                  />
                </Form.Item>
              );
            } else if (field.type === "DROPDOWN") {
              return (
                <Form.Item
                  key={index}
                  name={field.customer_field}
                  rules={[
                    {
                      required: false,
                      message: `Please select ${field.title}`,
                    },
                  ]}
                  label={
                    <Tooltip title={field.title}>
                      <span className="max-w-[150px] truncate inline-block">
                        {field.title}
                      </span>
                    </Tooltip>
                  }
                >
                  <Select
                    placeholder="Select"
                    options={(field.allowed_values || []).map(
                      (value: string) => ({
                        label: value,
                        value: value,
                      })
                    )}
                    filterOption={filterOption}
                    showSearch
                    allowClear
                    onChange={(value) =>
                      form.setFieldsValue({
                        [field.customer_field]: value ?? "",
                      })
                    }
                  />
                </Form.Item>
              );
            } else if (field.type === "NUMERIC") {
              return (
                <Form.Item
                  key={index}
                  name={field.customer_field}
                  rules={[
                    { required: false, message: `Please enter ${field.title}` },
                  ]}
                  label={
                    <Tooltip title={field.title}>
                      <span className="max-w-[150px] truncate inline-block">
                        {field.title}
                      </span>
                    </Tooltip>
                  }
                >
                  <InputNumber
                    placeholder={`Enter ${field.title}`}
                    style={{ width: "100%" }}
                    value={form.getFieldValue(field.customer_field)}
                    onChange={(value) =>
                      form.setFieldsValue({ [field.customer_field]: value })
                    }
                    onKeyDown={(e) => {
                      const allowedKeys = [
                        "Backspace",
                        "Tab",
                        "ArrowLeft",
                        "ArrowRight",
                        "Delete",
                      ];
                      if (allowedKeys.includes(e.key)) return;

                      if (!/^[0-9]$/.test(e.key)) {
                        e.preventDefault();
                      }
                    }}
                    addonAfter={
                      form.getFieldValue(field.customer_field) ? (
                        <CloseCircleOutlined
                          onClick={() =>
                            form.setFieldsValue({
                              [field.customer_field]: undefined,
                            })
                          }
                          style={{
                            cursor: "pointer",
                            color: "rgba(0,0,0,0.45)",
                          }}
                        />
                      ) : null
                    }
                  />
                </Form.Item>
              );
            } else {
              return (
                <Form.Item
                  key={index}
                  name={field.customer_field}
                  rules={[
                    { required: false, message: `Please enter ${field.title}` },
                  ]}
                  label={
                    <Tooltip title={field.title}>
                      <span className="max-w-[150px] truncate inline-block">
                        {field.title}
                      </span>
                    </Tooltip>
                  }
                >
                  <Input placeholder={`Enter ${field.title}`} allowClear />
                </Form.Item>
              );
            }
          })}
        </div>
      </div>

      {/* Task Section */}
      {customer ? null : (
        <div className="flex gap-2">
          <label>Create Task</label>
          <Checkbox
            checked={!!task}
            onChange={(e) => {
              if (orgLimits?.full && !!orgLimits?.limit) {
                setNudgePopup(true);
              } else {
                setTask(e?.target?.checked || false);
              }
            }}
          />
        </div>
      )}

      {task ? (
        <>
          <Divider plain orientation="left">
            Task
          </Divider>
          <div className="flex flex-col gap-2">
            <Form.Item
              label="Note"
              name="note"
              rules={[
                {
                  max: 200,
                  message: "Please enter less than 200 characters",
                },
              ]}
            >
              <Input.TextArea placeholder="Enter Note" allowClear />
            </Form.Item>
            <Form.Item
              label="Type"
              name="type"
              rules={[
                {
                  required: true,
                  message: "Please select Type",
                },
              ]}
            >
              <Select
                placeholder="Select Type"
                options={(taskTypesData || [])
                  .filter((t) => t.visible)
                  .map((taskType) => {
                    const { name, type } = taskType;
                    return {
                      label: name,
                      value: type,
                    };
                  })}
                filterOption={filterOption}
                showSearch
              />
            </Form.Item>
            <Form.Item
              label="Due Date"
              name="due_date"
              rules={[
                {
                  required: true,
                  message: "Please select Due Date",
                },
              ]}
            >
              <DatePicker
                format={DATE_TIME_FORMAT}
                placeholder={DATE_TIME_FORMAT}
                showTime={true}
                className="w-full"
                disabledDate={disableBeforeDate}
              />
            </Form.Item>
          </div>
        </>
      ) : null}

      {/* Retained AssignmentLimitNudge and related logic */}
      <Form.Item
        name={"assignee_user_id"}
        rules={[{ required: false, message: "Please select Assigned to User" }]}
        label={"Assigned to User"}
      >
        <Select
          placeholder={"Select Assigned to User"}
          options={[
            ...(members?.map((member) => {
              const label = `${member?.first_name || ""} ${
                member?.last_name || ""
              }`.trim();
              return {
                label,
                value: member.id,
              };
            }) || []),
            UNASSIGNED,
          ]}
          filterOption={filterOption}
          showSearch
          onChange={() => {
            if (isLiteOldOrNew) {
              setAssignNudge(true);
              form.setFieldsValue({
                assignee_user_id: UNASSIGNED.value,
              });
              return;
            }
          }}
        />
      </Form.Item>
      <Form.Item
        name={"lead_stage_id"}
        rules={[{ required: false, message: "Please select Lead Stage" }]}
        label={"Lead Stage"}
      >
        <Select
          placeholder={"Select Lead Stage"}
          allowClear
          options={leadStages?.map((leadStage) => {
            return {
              label: leadStage.title,
              value: leadStage.id,
            };
          })}
          filterOption={filterOption}
          showSearch
        />
      </Form.Item>

      <BasicModal open={nudgePopup} onCancel={() => setNudgePopup(false)}>
        <LeadFullNudge
          maxLeads={orgLimits?.limit || 0}
          onClose={() => setNudgePopup(false)}
          orgId={customer?.org_id || globalOrgId}
          planId={planId}
        />
      </BasicModal>

      <BasicModal open={assignNudge} onCancel={() => setAssignNudge(false)}>
        <AssignmentLimitNudge
          onClose={() => setAssignNudge(false)}
          orgId={selectedOrg}
        />
      </BasicModal>
    </Form>
  );
};

export const sourceTypeOptions = [
  { value: "CSV_UPLOAD", label: "CSV Upload" },
  { value: "FACEBOOK_INTEGRATION", label: "Facebook Integration" },
  { value: "PHONE_CONTACT", label: "Phone Contact" },
  { value: "WHATSAPP_MESSAGE", label: "Whatsapp Message" },
  { value: "WHATSAPP_INTEGRATION", label: "Whatsapp Integration" },
  { value: "PABBLY", label: "Pabbly" },
  { value: "OTHERS", label: "Others" },
];

export const typeOptions = [
  { value: "PABBLY", label: "PABBLY" },
  { value: "NOTIFICATION", label: "NOTIFICATION" },
  { value: "FACEBOOK DIRECT", label: "FACEBOOK DIRECT" },
  { value: "INDIAMART EMAIL", label: "INDIAMART EMAIL" },
  { value: "WEBHOOK", label: "WEBHOOK" },
];

export default CreateUpdateContactModal;
