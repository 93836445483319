import { Row, Spin } from "antd";
import { lazy, Suspense } from "react";
import { Routes, Route } from "react-router-dom";
import { AppLayout } from "./components/app-layout/app-layout.component";
import { GlobalModals } from "./components/global-modals/global-modals.component";
import { ProtectedRoute } from "./components/protected-route/protected-route.component";
import "./index.css";
import "./tailwind.css";
import "./app.css";
import { AppRoutes } from "./pages/app-routes.component";
import { io } from "socket.io-client";

const Login = lazy(() => import("./pages/login/login.page"));
const OrgWhatsapp = lazy(() => import("./pages/whatsapp/org-whatsapp.page"));
const ChatView = lazy(() => import("./pages/chat-view/chat-view.page"));
const WPChatPage = lazy(() => import("./pages/wp-chat/wp-chat.page"));
const ChatBot = lazy(() => import("./pages/chatbot/chatbot"));

export const socket = io("https://prod-api.superfone.co.in", {
  path: "/superfone/socket.io/", // Specifies the path where Socket.IO is served
  transports: ["websocket", "polling"], // Uses WebSocket as transport; add 'polling' if you want fallback
  rejectUnauthorized: false,
  withCredentials: true, // Include cookies
  extraHeaders: {
    // Optional: Specify any other headers here
  },
});

// Listening for connection
socket.on("connect", () => {
  console.log("Connected to Socket.IO server:", socket.id);
});

// Handling error or reconnection attempts
socket.on("connect_error", (error) => {
  console.error("Connection failed:", error);
});

const App = () => {
  return (
    <Suspense
      fallback={
        <Row
          justify="center"
          align="middle"
          style={{
            minHeight: "100vh",
            minWidth: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spin spinning={true} />
        </Row>
      }
    >
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/login-admin" element={<Login />} />
        <Route
          path="/whatsapp/:orgId"
          element={
            <ProtectedRoute>
              <OrgWhatsapp />
            </ProtectedRoute>
          }
        />
        <Route
          path="/chatbot"
          element={
            <ProtectedRoute>
              <ChatBot />
            </ProtectedRoute>
          }
        />
        <Route
          path="/chat-new/:orgId"
          element={
            <ProtectedRoute>
              <WPChatPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/chat-new/:orgId/:clientWaNumber"
          element={
            <ProtectedRoute>
              <ChatView />
            </ProtectedRoute>
          }
        />
        <Route
          path="/*"
          element={
            <>
              <ProtectedRoute>
                <AppLayout>
                  <AppRoutes />
                </AppLayout>
              </ProtectedRoute>
              <GlobalModals />
            </>
          }
        />
        {/* <Route
          path="/chat-new:orgId"
          element={
            <ProtectedRoute>
              <WPChatPage />
            </ProtectedRoute>
          }
        /> */}
      </Routes>
    </Suspense>
  );
};

export default App;
