export const countryCodes = [
  { code: "+91", label: "IN", minimumLength: 10 },
  { code: "+86", label: "CN", minimumLength: 11 },
  { code: "+7", label: "RU", minimumLength: 10 },
  { code: "+92", label: "PK", minimumLength: 10 },
  { code: "+61", label: "AU", minimumLength: 9 },
  { code: "+977", label: "NP", minimumLength: 10 },
  { code: "+66", label: "TH", minimumLength: 9 },
  { code: "+60", label: "MY", minimumLength: 9 },
  { code: "+81", label: "JP", minimumLength: 10 },
  { code: "+1", label: "US", minimumLength: 10 },
  { code: "+44", label: "GB", minimumLength: 10 },
  { code: "+49", label: "DE", minimumLength: 11 },
  { code: "+33", label: "FR", minimumLength: 9 },
  { code: "+27", label: "ZA", minimumLength: 9 },
];
