import { PayloadAction, createSelector, createSlice } from "@reduxjs/toolkit";
import { getHoursRemaining, MessageTypesEnum } from "../../pages/wp-chat/utils";
import { WhatsappMessage } from "../api/types/get-whatsapp-messages.types";
import {
  // ConversationLeft,
  WhatsappContact,
} from "../api/types/get-whatsapp-contacts.types";
import {
  getContactsFromStorage,
  getMessagesFromStorage,
} from "../../pages/wp-chat/locally-stored-data";
import { RootState } from "../store";
import { TabNames } from "../../components/purchase/constants";

export const isMobileView = window.innerWidth <= 993 || !!window?.JSBridge;

export type ScrollToIndexType = { index: number; offset: number };

export const initialScrollToIndexType: ScrollToIndexType = {
  index: -1,
  offset: 0,
};

export type DraftStagesType = {
  contactID: string;
  message: string;
  // scrollState: number;
  scrollState: ScrollToIndexType;
};

export type WPChatReducerType = Readonly<{
  rightContextMenu: RightContextMenuType;
  repliedTo: RepliedToType;
  isMobileChatView: boolean;
  activeMobileScreen: string;
  activeChat: WhatsappContact | null;
  activeChatSearch: boolean;
  newMessageSent: boolean;
  newMessageReceived: boolean;
  selectedMessages: WhatsappMessage[];
  draftStages: DraftStagesType[];
  contacts: WhatsappContact[];
  messages: { [x: string]: WhatsappMessage[] };
  activeTab: TabNames;
  searchText: string;
}>;

export type RepliedToType = {
  name: string;
  message: string;
  type: string;
  data: any | WhatsappMessage;
};

export type RightContextMenuType = Readonly<{
  reply: boolean;
  react: boolean;
  forward: boolean;
  copy: boolean;
  info: boolean;
  addTask: boolean;
  writeNote: boolean;
  delete: boolean;
}>;

export const initialRightContextMenu: RightContextMenuType = {
  reply: false,
  react: false,
  forward: false,
  copy: false,
  info: false,
  addTask: false,
  writeNote: false,
  delete: false,
};

export const initialRepliedTo: RepliedToType = {
  name: "",
  message: "",
  type:
    MessageTypesEnum.TEXT ||
    MessageTypesEnum.AUDIO ||
    MessageTypesEnum.DOCUMENT ||
    MessageTypesEnum.IMAGE ||
    MessageTypesEnum.VIDEO,
  data: null,
};

type ActiveMobileScreenType = {
  LEFT_SCREEN: string;
  MID_SCREEN: string;
  RIGHT_SCREEN: string;
};

export const EnumActiveMobileScreen: ActiveMobileScreenType = {
  LEFT_SCREEN: "LEFT",
  MID_SCREEN: "MID",
  RIGHT_SCREEN: "RIGHT",
};

export const initialDraftStage: DraftStagesType = {
  contactID: "",
  message: "",
  scrollState: initialScrollToIndexType,
};

export const initialState: WPChatReducerType = {
  rightContextMenu: { ...initialRightContextMenu },
  repliedTo: { ...initialRepliedTo },
  isMobileChatView: window.innerWidth <= 993,
  activeMobileScreen: EnumActiveMobileScreen.LEFT_SCREEN,
  activeChat: null,
  activeChatSearch: false,
  newMessageSent: false,
  newMessageReceived: false,
  selectedMessages: [],
  draftStages: [],
  contacts: getContactsFromStorage(),
  messages: getMessagesFromStorage(),
  activeTab: TabNames.ALL_CHATS,
  searchText: "",
};

export const wPChatReducerSlice = createSlice({
  name: "wpChatReducer",
  initialState,
  reducers: {
    setInitialRightContextMenuAction: (state) => {
      state.rightContextMenu = { ...initialRightContextMenu };
    },
    setUpdateRightContextMenuAction: (
      state,
      action: PayloadAction<RightContextMenuType>
    ) => {
      state.rightContextMenu = action.payload;
    },
    setInitialRepliedToAction: (state) => {
      state.repliedTo = { ...initialRepliedTo };
    },
    setUpdateRepliedToAction: (state, action: PayloadAction<RepliedToType>) => {
      state.repliedTo = action.payload;
    },
    setIsMobileChatViewAction: (state, action: PayloadAction<boolean>) => {
      state.isMobileChatView = action.payload;
    },
    setActiveMobileScreenAction: (state, action: PayloadAction<string>) => {
      state.activeMobileScreen = action.payload;
    },
    setActiveChat: (
      state,
      action: PayloadAction<{
        activeChat: WhatsappContact | null;
        file: string;
      }>
    ) => {
      if (isMobileView) {
        state.activeChat = action.payload.activeChat;
      } else {
        if (action.payload.activeChat) {
          state.activeChat = action.payload.activeChat;
        }
      }
    },
    setExpirationDate: (state, action: PayloadAction<string>) => {
      if (state?.activeChat?.conversation?.expiration_date) {
        state.activeChat.conversation.expiration_date = action.payload;
      }
    },
    setActiveChatSearchAction: (state, action: PayloadAction<boolean>) => {
      state.activeChatSearch = action.payload;
    },
    setNewMessageSentAction: (state, action: PayloadAction<boolean>) => {
      state.newMessageSent = action.payload;
    },
    setNewMessageReceivedAction: (state, action: PayloadAction<boolean>) => {
      state.newMessageReceived = action.payload;
    },
    setSelectedMessageAction: (
      state,
      action: PayloadAction<WhatsappMessage[]>
    ) => {
      state.selectedMessages = action.payload;
    },
    setDraftStagesAction: (state, action: PayloadAction<DraftStagesType[]>) => {
      state.draftStages = action.payload;
    },
    setContacts: (state, action: PayloadAction<WhatsappContact[]>) => {
      if (action?.payload?.length) {
        state.contacts = action.payload;
      }
    },
    setMessages: (
      state,
      action: PayloadAction<{
        messages: WhatsappMessage[];
        file: string;
        chat_id: string;
      }>
    ) => {
      if (action?.payload?.chat_id) {
        state.messages[action.payload.chat_id] = action.payload?.messages || [];
      }
    },
    setActiveTab: (state, action: PayloadAction<TabNames>) => {
      state.activeTab = action.payload;
    },
    setSearchText: (state, action: PayloadAction<string>) => {
      state.searchText = action.payload;
    },
  },
});

export const {
  setInitialRightContextMenuAction,
  setUpdateRightContextMenuAction,
  setInitialRepliedToAction,
  setUpdateRepliedToAction,
  setIsMobileChatViewAction,
  setActiveMobileScreenAction,
  setActiveChat,
  setActiveChatSearchAction,
  setNewMessageSentAction,
  setNewMessageReceivedAction,
  setSelectedMessageAction,
  setDraftStagesAction,
  setContacts,
  setMessages,
  setActiveTab,
  setSearchText,
  setExpirationDate,
} = wPChatReducerSlice.actions;

export const wpChatReducer = wPChatReducerSlice.reducer;

export const selectWpReducer = (state: RootState) => state.wpChatReducer;

export const selectSearchText = createSelector(
  [selectWpReducer],
  (wpReducer) => wpReducer?.searchText || ""
);

export const selectContacts = createSelector(
  [selectWpReducer],
  (wpReducer) => wpReducer?.contacts || []
);

export const selectMessages = createSelector(
  [selectWpReducer],
  (wpReducer) => wpReducer?.messages || {}
);

export const selectActiveMobileScreen = createSelector(
  [selectWpReducer],
  (wpReducer) => wpReducer.activeMobileScreen
);

export const selectActiveChat = createSelector(
  [selectWpReducer],
  (wpReducer) => wpReducer.activeChat
);

export const selectActiveTab = createSelector(
  [selectWpReducer],
  (wpReducer) => wpReducer.activeTab
);

export const selectExpirationDate = createSelector(
  [selectActiveChat],
  (chat) => {
    return chat?.conversation?.expiration_date || "";
  }
);

export const selectChatTimeLeft = createSelector(
  [selectExpirationDate],
  (expiration_date) => {
    return expiration_date ? getHoursRemaining(expiration_date) : 0;
  }
);

export const selectMyChats = createSelector([selectContacts], (contacts) => {
  return contacts.filter(
    (contact) =>
      contact?.lead_details?.assignee_user_id ===
      Number(localStorage.getItem("userId"))
  );
});

export const selectUnassignedChats = createSelector(
  [selectContacts],
  (contacts) => {
    return contacts.filter(
      (contact) => contact?.lead_details?.assignee_user_id === null
    );
  }
);

export const selectTabCounts = createSelector(
  [selectContacts, selectMyChats, selectUnassignedChats],
  (allChats, myChats, unassignedChats) => {
    return {
      [TabNames.ALL_CHATS]:
        allChats?.filter((item) => item.unread > 0)?.length || 0,
      [TabNames.MY_CHATS]:
        myChats?.filter((item) => item.unread > 0)?.length || 0,
      [TabNames.UNASSIGNED]:
        unassignedChats?.filter((item) => item.unread > 0)?.length || 0,
    };
  }
);

export const selectFilteredChats = createSelector(
  [
    selectSearchText,
    selectActiveTab,
    selectContacts,
    selectMyChats,
    selectUnassignedChats,
  ],
  (searchText, activeTab, allChats, myChats, unassignedChats) => {
    if (searchText) {
      return sortContacts(
        allChats.filter(
          (c) =>
            c?.contact?.profile?.name
              ?.toLocaleLowerCase?.()
              ?.trim()
              ?.includes(searchText?.toLowerCase?.()?.trim()) ||
            c?.contact?.wa_id
              ?.toLocaleLowerCase?.()
              ?.trim()
              ?.includes(searchText?.toLocaleLowerCase?.()?.trim()) ||
            c?.lead_details?.first_name
              ?.toLocaleLowerCase?.()
              ?.trim()
              ?.includes(searchText?.toLocaleLowerCase?.()?.trim()) ||
            c?.lead_details?.last_name
              ?.toLocaleLowerCase?.()
              ?.trim()
              ?.includes(searchText?.toLocaleLowerCase?.()?.trim())
        )
      );
    } else if (activeTab === TabNames.MY_CHATS) {
      return sortContacts(myChats);
    } else if (activeTab === TabNames.UNASSIGNED) {
      return sortContacts(unassignedChats);
    }
    return sortContacts(allChats);
  }
);

export const sortContacts = (contacts: WhatsappContact[] | undefined) => {
  if (!contacts) return [];

  return [...contacts]?.sort((a, b) => {
    const dateA = a?.last_message_at
      ? new Date(a?.last_message_at).getTime()
      : 0;
    const dateB = b?.last_message_at
      ? new Date(b?.last_message_at).getTime()
      : 0;

    return dateB - dateA;
  });
};
