import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {
  SendMessagePayload,
  SendMessageResponse,
} from "./types/send-message.types";
import { WhatsappContact } from "./types/get-whatsapp-contacts.types";
import { WhatsappMessage } from "./types/get-whatsapp-messages.types";
import {
  PresignURLPayload,
  PresignURLResponse,
} from "./types/presign-url.types";
import { WhatsappTemplate } from "./types/get-whatsapp-templates.types";
import { CreateWhatsappTemplatePayload } from "./types/create-whatsapp-template.types";
import { UpdateWhatsappTemplatePayload } from "./types/update-whatsapp-template.types";
import { api, GenericAPIResponse } from "./api";
import {
  setContactsInStorage,
  setMessagesInStorage,
} from "../../pages/wp-chat/locally-stored-data";
import { Template } from "./types/get-template.types";

export const whatsappApi = createApi({
  reducerPath: "whatsappApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_HOST,
    credentials: "include",
  }),
  endpoints: (builder) => ({
    sendMessage: builder.mutation<SendMessageResponse, SendMessagePayload>({
      query: (body) => ({
        url: `/dragonfly/whatsapp/send`,
        method: "post",
        body,
      }),
      transformResponse: (response: GenericAPIResponse<SendMessageResponse>) =>
        response.data,
    }),
    sendCampaigns: builder.mutation({
      query: (body) => ({
        url: `/dragonfly/whatsapp/campaigns`,
        method: "post",
        body,
      }),
      transformResponse: (response: any) => response,
    }),
    getWhatsappContacts: builder.query<WhatsappContact[], number>({
      query: (org_id) => ({
        url: `/dragonfly/whatsapp/contacts?org_id=${org_id}`,
      }),
      transformResponse: (response: GenericAPIResponse<WhatsappContact[]>) => {
        const contacts = response.data;
        setTimeout(() => {
          setContactsInStorage(contacts || []);
        }, 0);
        return contacts;
      },
    }),
    getContactMessages: builder.query<WhatsappMessage[], string>({
      query: (chat_id) => ({
        url: `/dragonfly/whatsapp/${chat_id}/messages`,
      }),
      transformResponse: (
        response: GenericAPIResponse<WhatsappMessage[]>,
        _,
        chat_id
      ) => {
        const messages = response.data;
        setTimeout(() => {
          setMessagesInStorage({ chat_id, messages });
        }, 0);
        return response.data;
      },
    }),
    presignURL: builder.mutation<PresignURLResponse, PresignURLPayload>({
      query: (payload) => ({
        url: `/s3/presignURL?file_name=${payload.file_name}&file_type=${payload.file_type}&public=true`,
        method: "get",
      }),
      transformResponse: (response: GenericAPIResponse<PresignURLResponse>) =>
        response.data,
    }),
    getWhatsappTemplates: builder.query<
      WhatsappTemplate[],
      { token?: string; isNewChat?: boolean; org_id?: number }
    >({
      query: (payload) => {
        const { token, isNewChat, org_id } = payload;
        if (isNewChat) {
          return {
            url: `/dragonfly/whatsapp/message_templates${
              org_id ? `?org_id=${org_id}&limit=100` : "?limit=100"
            }`,
          };
        }
        return {
          url: `https://whatsapp-api.superfone.co.in/v1/templates`,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
      },
      transformResponse: (
        response: GenericAPIResponse<WhatsappTemplate[]>,
        meta,
        arg
      ) => {
        return arg?.isNewChat
          ? (response as any)?.data?.data || []
          : response.data;
      },
    }),
    createWhatsappTemplate: builder.mutation<
      any,
      CreateWhatsappTemplatePayload
    >({
      query: (body) => ({
        url: `/dragonfly/whatsapp/message_templates`,
        method: "post",
        body,
      }),
      transformResponse: (response: GenericAPIResponse<WhatsappTemplate>) =>
        response.data,
    }),
    updateWhatsappTemplate: builder.mutation<
      any,
      UpdateWhatsappTemplatePayload
    >({
      query: (payload) => {
        const { id, ...body } = payload;
        return {
          url: `/dragonfly/whatsapp/message_templates/${id}`,
          method: "post",
          body,
        };
      },
      transformResponse: (response: GenericAPIResponse<WhatsappTemplate>) =>
        response.data,
    }),
    markAsRead: builder.mutation<any, MarkAsReadPayloadType>({
      query: (payload) => {
        const { ...body } = payload;
        return {
          url: `/dragonfly/whatsapp/mark_as_read`,
          method: "post",
          body,
        };
      },
      transformResponse: (response: GenericAPIResponse<WhatsappTemplate>) =>
        response.data,
    }),
    markAsResolved: builder.mutation<any, MarkAsReadPayloadType>({
      query: (payload) => {
        const { ...body } = payload;
        return {
          url: `/dragonfly/whatsapp/mark_as_resolved`,
          method: "post",
          body,
        };
      },
      transformResponse: (response: GenericAPIResponse<WhatsappTemplate>) =>
        response.data,
    }),
    updateTemplateMedia: builder.mutation<
      any,
      {
        template_id: string;
        media_link: string;
        file_name: string;
        type: string;
      }
    >({
      query: (payload) => {
        const { template_id, ...body } = payload;
        return {
          url: `/dragonfly/whatsapp/message_templates/${template_id}/media`,
          method: "put",
          body,
        };
      },
      transformResponse: (response: GenericAPIResponse<any>) => response.data,
    }),
    getTemplate: builder.query<Template, string>({
      query: (template_id) => ({
        url: `/dragonfly/whatsapp/message_templates/${template_id}`,
      }),
      transformResponse: (response: GenericAPIResponse<Template>) =>
        response.data,
    }),
    getAllTemplates: builder.query<
      { templates: WhatsappTemplate[]; org_id: number }[],
      void
    >({
      async queryFn(_arg, _queryApi, _extraOptions) {
        const { dispatch, getState } = _queryApi;
        const orgsResponse = await api.endpoints.getOrgs.initiate(undefined, {
          forceRefetch: false,
        })(dispatch, getState, undefined);
        const orgIds = orgsResponse?.data?.map((o) => o.id) || [];
        const promises = orgIds.map((orgId) =>
          whatsappApi.endpoints.getWhatsappTemplates.initiate(
            { isNewChat: true, org_id: orgId },
            {
              forceRefetch: true,
            }
          )(dispatch, getState, undefined)
        );
        const result = await Promise.allSettled(promises);
        const templatesArray: {
          templates: WhatsappTemplate[];
          org_id: number;
        }[] = [];
        result.map((response) => {
          const { status } = response;
          if (status === "fulfilled") {
            const s = response.value;
            if (s?.data?.length) {
              const org_id = response?.value?.originalArgs?.org_id || 0;
              const templates = s?.data || [];
              templatesArray.push({ templates, org_id });
            }
          }
          return null;
        });
        return {
          data: (templatesArray || []) as {
            templates: WhatsappTemplate[];
            org_id: number;
          }[],
        };
      },
    }),
  }),
});

export const {
  useGetWhatsappContactsQuery,
  useLazyGetWhatsappContactsQuery,
  useSendMessageMutation,
  useSendCampaignsMutation,
  useGetContactMessagesQuery,
  useLazyGetContactMessagesQuery,
  usePresignURLMutation,
  useGetWhatsappTemplatesQuery,
  useCreateWhatsappTemplateMutation,
  useUpdateWhatsappTemplateMutation,
  useMarkAsReadMutation,
  useUpdateTemplateMediaMutation,
  useGetTemplateQuery,
  useLazyGetTemplateQuery,
  useMarkAsResolvedMutation,
  useGetAllTemplatesQuery,
} = whatsappApi;

export interface MarkAsReadPayloadType {
  message_id: string;
}
