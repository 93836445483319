import { useMemo } from "react";
import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { selectSiderCollapsed } from "../../../store/global/global.selector";
import { decrypt } from "../../../utils/utils";
import { setSidebar } from "../../../store/global/global.reducer";
import {
  useGetOrgQuery,
  useGetSummariesQuery,
  useUsersSyncQuery,
} from "../../../store/api/api";
import { useDispatch } from "react-redux";
import {
  getContactWithCountryCode,
  removeCountryCode,
} from "../../../utils/remove-country-code";
import {
  selectIsFreeTier,
  selectUserPaidStatus,
} from "../../../store/api/api.selector";
import { AppRoutesObject } from "../../../pages/app-routes.component";
import { useLocation, useMatch } from "react-router-dom";
import { LogoutButton } from "../../logout-button/logout-button.component";
import { LiveCall } from "../../live-call/live-call.component";

type TopBarProps = {
  showToggle?: boolean;
  className?: string;
};

export const Topbar = (props: Readonly<TopBarProps>) => {
  const { showToggle = true, className } = props;
  const siderCollapsed = useSelector(selectSiderCollapsed);
  const dispatch = useDispatch();
  const onMenuClick = () => {
    dispatch(setSidebar(!siderCollapsed));
  };

  const { user: usersSync, isTrialUser } = useUsersSyncQuery(undefined, {
    selectFromResult: ({ data }) => selectUserPaidStatus(data),
  });
  const user = usersSync?.user;
  const phone = removeCountryCode({ phoneNumber: user?.phone }).phone;
  const { name: userName, phone: superfoneNumber } = getContactWithCountryCode({
    number: usersSync?.org?.superfone_number,
    first_name: user?.first_name || "",
    last_name: user?.last_name || "",
  });
  const location = useLocation();
  const isReportsPage = location.pathname.includes(AppRoutesObject.Reports.url);
  const isSettingsPage = location.pathname.includes(
    AppRoutesObject.Settings.url
  );
  const match = useMatch(
    isReportsPage
      ? `${AppRoutesObject.Reports.url}/:orgId`
      : isSettingsPage
      ? `${AppRoutesObject.Settings.url}/:orgId`
      : ""
  );
  const matchedOrgId = match?.params?.orgId || 0;
  const orgId = useMemo(() => {
    return Number(decrypt(matchedOrgId));
  }, [matchedOrgId]);

  const { data: org } = useGetOrgQuery(orgId, {
    skip: !orgId,
  });
  const { phone: orgSuperfoneNumber } = getContactWithCountryCode({
    number: org?.superfone_number,
  });
  const { isFreeTier } = useGetSummariesQuery(undefined, {
    selectFromResult: ({ data }) => selectIsFreeTier(data || []),
  });

  return (
    <header className={`${className || ""} relative`}>
      <div className="flex items-center pl-2.5 gap-2.5">
        {showToggle ? (
          <div className="cursor-pointer text-xl" onClick={onMenuClick}>
            {siderCollapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
          </div>
        ) : null}
        {orgId && (isReportsPage || isSettingsPage) ? (
          <div className="font-semibold px-4 text-base flex gap-2 items-center">
            <div>{org?.name || ""}</div>
            <div className="flex gap-1 items-center font-medium">
              {orgSuperfoneNumber.map((e, i) => (
                <span key={i}>{e}</span>
              ))}
            </div>
          </div>
        ) : isTrialUser ? (
          <div className="font-semibold px-4 text-base flex gap-2 items-center">
            <div>{usersSync?.org?.name || ""}</div>
            <div className="flex gap-1 items-center font-medium">
              {superfoneNumber.map((e, i) => (
                <span key={i}>{e}</span>
              ))}
            </div>
          </div>
        ) : null}
      </div>
      <div className="flex items-center pr-2.5 gap-2.5">
        <div className="flex flex-col">
          <div className="text-base flex gap-2">
            <strong
              className="max-w-[300px] text-ellipsis whitespace-nowrap overflow-hidden"
              title={userName}
            >
              {userName}
            </strong>
          </div>
          <div className="text-sm gap-2 self-end text-[#455464]">{phone}</div>
        </div>
        <LogoutButton>Logout</LogoutButton>
      </div>
      {isFreeTier ? null : (
        <div className="absolute left-1/2 top-1/2 -translate-x-full -translate-y-1/2">
          <LiveCall />
        </div>
      )}
    </header>
  );
};
